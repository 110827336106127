import React from 'react';
import '../styles/widget.css';
import { FormattedMessage } from 'react-intl';

function PowerZapWidget({
    message,
    user,
    buttonName,
}) {

    return (
        <div className="WA_Chat_Widget row">
            <div className="col-12 d-flex align-items-center justify-content-center">
                <div className="WA_ChatBox">
                    <div className="WA_ChatBox_Header">
                        <div className="avatarBox">
                            <img src={require(`../assets/img/power-zap.png`)} alt="PowerZap" />
                        </div>
                        <div className="infoBox">
                            <span className="name"><FormattedMessage id="widget_title" /></span>
                        </div>
                        <button className="WA_Close">
                            <img src={require(`../assets/img/close.png`)} alt="Close" />
                        </button>
                    </div>
                    <div className="WA_ChatBox_Body">
                        <div className="message">
                            <span className="username">{user ? user : <FormattedMessage id="widget_user" />}</span>
                            <div className="message_content pt-1">
                                <p>{message ? message : <FormattedMessage id="widget_msg" />}</p>
                            </div>
                        </div>
                    </div>
                    <div className="WA_ChatBox_Footer">
                        <a className="btn btn-whatsapp" href="http://wa.me/92123435464" target="_blank">{buttonName ? buttonName : <FormattedMessage id="widget_btn" />}</a>
                    </div>
                </div>
            </div>

            <div className="col-12 d-flex align-items-center justify-content-end">
                <div className="WA_FloatingButton">
                    <img src={require(`../assets/img/whatsapp.png`)} alt="WhatsApp" />
                </div>
            </div>

        </div>
    );
}

export default PowerZapWidget;
