export const la = {
    hearder_title: 'PowerZap WhatsApp API for Bitrix24',
    header_btn: 'CREA EL TUYO AHORA',
    header_text_1: '¡Capture instantáneamente los datos de contacto de los visitantes de su sitio web!',
    header_text_2: 'Con el botón PowerZap WhatsApp, abres las puertas para atraer visitantes interesados ​​directamente en tus ofertas. ¿Y la mejor parte? ¡La instalación se simplifica y solo toma 2 minutos!',

    info_title: 'Cómo funciona nuestro generador:',
    info_subtitle_1: 'Generando el código del botón de WhatsApp',
    info_text_1: 'Después de completar los campos anteriores, se generará automáticamente un código.',
    info_item_1: '1. Copie y pegue el código en la etiqueta {"</body>"} de su sitio web.',
    info_item_2: '2. Una vez instalado, comenzará a recibir mensajes en el número de WhatsApp que ingresó anteriormente.',
    info_item_3: '3. Con el número ya conectado a Bitrix24 con PowerZap, cada nuevo cliente potencial que muestre interés puede ser automáticamente un nuevo cliente potencial en su CRM Bitrix24 y distribuido a la cola de agentes.',
    info_subtitle_2: 'Aplicando el código a tu sitio web',
    info_text_2: 'Si no entiende HTML pero no quiere depender de un desarrollador',
    info_text_3: 'No se preocupe. Nuestro tutorial intuitivo estará disponible para guiarle a través de todo el proceso.',
    info_text_4: 'Si no entiendes HTML y no quieres arriesgarte',
    info_text_5: 'Simplemente envíe el código a un desarrollador de confianza.',

    form_title: 'Creemos y personalicemos tu Botón de WhatsApp',
    form_user: '¿Qué nombre de usuario mostrar en el Botón de WhatsApp?',
    form_invalid_user: 'Por favor ingrese un nombre de usuario válido.',
    form_msg: '¿Qué mensaje deberías mostrar en la parte superior a tus clientes?',
    form_placeholder_msg: 'Hola, accedí al sitio web X y tengo algunas preguntas.',
    form_invalid_msg: 'Por favor ingrese un mensaje válido.',
    form_btn: '¿Qué texto mostrar en el Botón de WhatsApp?',
    form_placeholder_btn: 'Comenzar chat',
    form_number: '¿Qué número de WhatsApp quieres usar?',
    form_submit: 'CREAR BOTÓN WHATSAPP',
    form_step: 'PASO 1 DE 2',
    form_search: 'Buscar',

    widget_title: 'PowerZap Chat',
    widget_user: 'PowerZap Chat',
    widget_msg: 'Hola, accedí al sitio web X y tengo algunas preguntas.',
    widget_btn: 'Comenzar chat',

    code_title: 'Activa fácilmente el Botón de WhatsApp y empieza a usarlo',
    code_text_1: 'Activa tu Botón de WhatsApp con el siguiente código',
    code_text_2: 'Copie y pegue este código antes de la etiqueta </body>',
    code_alert: 'Código copiado al portapapeles.',
    code_btn: 'COPIAR EL CÓDIGO',
    code_text_3: '¡Ahora simplemente active el código en su sitio web y comience a cosechar las recompensas! ¿Aún tienes dudas? Consulte nuestro tutorial a continuación para obtener ayuda adicional.',
    code_step: 'VOLVER AL PASO 1',
    code_link_1: 'Entiende cómo activar:',
    code_link_2: 'Mira nuestros tutoriales',
    code_link_3: 'Tutorial para el sitio web de WordPress',
    code_link_4: 'Tutorial del sitio web Bitrix24',
    code_link_5: 'Tutorial para otras plataformas',
    code_link_6: 'Qué hacer antes de conectar un número en Bitrix24 CRM',
    code_link_7: 'Cómo preparar el número antes de conectarlo a Bitrix24 CRM',
}