import WidgetForm from './components/WidgetForm';
import PowerZapWidget from './components/PowerZapWidget';
import { useRef, useState } from 'react';
import WidgetCode from './components/WidgetCode';
import { FormattedMessage, IntlProvider } from 'react-intl';
import { br } from './languages/br';
import { en } from './languages/en';
import { la } from './languages/la';
import { Select } from 'antd';

const messages = {
  br,
  en,
  la
};

function App() {
  const [message, setMessage] = useState('');
  const [whatsappNumber, setWhatsappNumber] = useState('55');
  const [user, setUser] = useState('');
  const [buttonName, setButtonName] = useState('');
  const [locale, setLocale] = useState('br');

  const [generatedWidget, setGeneratedWidget] = useState(true);
  const formSectionRef = useRef(null);

  const handleChangeLanguage = (newLocale) => {
    setLocale(newLocale);

  if(newLocale == 'en') {
    setWhatsappNumber('1');
  } else if (newLocale == 'la') {
    setWhatsappNumber('34');
  } else {
    setWhatsappNumber('55');
  }
};

const scrollToForm = () => {
  setTimeout(() => {
    const formSection = formSectionRef.current;
    if (formSection) {
      formSection.scrollIntoView({ behavior: 'smooth' });
    }
  }, 100);
}

return (
  <IntlProvider locale={locale} messages={messages[locale]}>
    <div>
      <header>
        <nav className="navbar navbar-expand-lg navbar-light bg-navbar shadow">
          <div className="container-fluid">

            <div className="col-11 align-items-center justify-content-center text-center">
              <a href="#" className="pr-5">
                <img src={require(`./assets/img/power-zap.png`)} height="50px" />
              </a>
              <span className="nav-text">
                <FormattedMessage id="hearder_title" />
              </span>
            </div>

            <div className="col-1 align-items-left justify-content-left text-left">
              <Select
                defaultValue="br"
                onChange={handleChangeLanguage}
                options={[
                  { value: 'br', label: <img src={require(`./assets/img/brasil.png`)} width={"20px"} /> },
                  { value: 'en', label: <img src={require(`./assets/img/eua.png`)} width={"20px"} /> },
                  { value: 'la', label: <img src={require(`./assets/img/espanha.png`)} width={"20px"} /> },
                ]}
              />
            </div>
          </div>
        </nav>
      </header>

      {generatedWidget ? (
        <>
          <div className="container-fluid d-flex align-items-center justify-content-center text-center bg-custom">
            <div className="row">
              <div className="col-12 pt-5">
                <a href="#divForm" className="btn btn-primary btn-lg">
                  <FormattedMessage id="header_btn" />
                </a>
              </div>

              <div className="col-12 pt-4 title-header">
                <h3><FormattedMessage id="header_text_1" /></h3>
              </div>

              <div className="col-12 pt-2 text-header">
                <h6><FormattedMessage id="header_text_2" /></h6>
              </div>
            </div>
          </div>

          <div className="container pt-4" id="divForm" ref={formSectionRef}>
            <div className="row p-3">
              <div className="col-md-6 col-12 p-3 form shadow">
                <WidgetForm
                  message={message}
                  setMessage={setMessage}
                  whatsappNumber={whatsappNumber}
                  setWhatsappNumber={setWhatsappNumber}
                  user={user}
                  setUser={setUser}
                  buttonName={buttonName}
                  setButtonName={setButtonName}
                  locale={locale}
                  setGeneratedWidget={setGeneratedWidget} />
              </div>

              <div className="col-md-6 col-12 pt-5">
                <PowerZapWidget
                  message={message}
                  user={user}
                  buttonName={buttonName} />
              </div>
            </div>
          </div>

          <div class="container-fluid bg-custom-info p-4">
            <div className="row">
              <h2 className="d-flex align-items-center justify-content-center">
                <FormattedMessage id="info_title" />
              </h2>
              <div className="container">
                <div className="row">
                  <div className="col-md-6 col-12 p-5">
                    <h2><FormattedMessage id="info_subtitle_1" /></h2>
                    <h5><FormattedMessage id="info_text_1" /></h5><br />
                    <span><FormattedMessage id="info_item_1" /></span><br /><br />
                    <span><FormattedMessage id="info_item_2" /></span><br /><br />
                    <span><FormattedMessage id="info_item_3" /></span><br />
                  </div>

                  <div className="col-md-6 col-12 p-5">
                    <h2><FormattedMessage id="info_subtitle_2" /></h2>
                    <h5><FormattedMessage id="info_text_2" /></h5>
                    <span><FormattedMessage id="info_text_3" /></span>
                    <h5><FormattedMessage id="info_text_4" /></h5>
                    <span><FormattedMessage id="info_text_5" /></span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <footer className='p-3 d-flex align-items-center justify-content-center'>
            <span> © 2024 </span><img src={require(`./assets/img/logo_br24.png`)} height={20} width={100} />
          </footer>
        </>
      ) : (
        <WidgetCode
          message={message}
          user={user}
          buttonName={buttonName}
          whatsappNumber={whatsappNumber}
          setGeneratedWidget={setGeneratedWidget}
          scrollToForm={scrollToForm} />
      )}
    </div>
  </IntlProvider>
);
}

export default App;
