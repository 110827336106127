export const en = {
    hearder_title: 'PowerZap WhatsApp API for Bitrix24',
    header_btn: 'CREATE YOURS NOW',
    header_text_1: 'Instantly capture the contact details of your website visitors!',
    header_text_2: 'With the PowerZap WhatsApp Button, you open the doors to engaging visitors interested directly in your offers. And the best part? Installation is simplified, taking just 2 minutes!',
    
    info_title: 'How our generator works:',
    info_subtitle_1: 'Generating the WhatsApp Button code',
    info_text_1: 'After filling in the fields above, a code will be automatically generated.',
    info_item_1: '1. Copy and paste the code into the {"</body>"} tag on your website.',
    info_item_2: '2. Once installed, you will start receiving messages on the WhatsApp number you entered above.',
    info_item_3: '3. With the number already connected to Bitrix24 with PowerZap, each new lead that shows interest can automatically be a new Lead in your Bitrix24 CRM and distributed to the queue of agents.',
    info_subtitle_2: 'Applying the code to your website',
    info_text_2: "If you don't understand HTML but don't want to depend on a developer",
    info_text_3: "Don't worry. Our intuitive tutorial will be available to guide you through the entire process.",
    info_text_4: "If you don't understand HTML and don't want to take the risk",
    info_text_5: 'Just forward the code to a trusted developer.',

    form_title: "Let's create and customize your WhatsApp Button",
    form_user: 'What username to show on the WhatsApp Button?',
    form_invalid_user: 'Please enter a valid username.',
    form_msg: 'What message should you display at the top for your customers?',
    form_placeholder_msg: 'Hello, I visited website X and I have some questions.',
    form_invalid_msg: 'Please enter a valid message.',
    form_btn: 'What text to show on the WhatsApp Button?',
    form_placeholder_btn: 'Start Chat',
    form_number: 'Which WhatsApp number do you want to use?',
    form_submit: 'CREATE WHATSAPP BUTTON',
    form_step: 'STEP 1 OF 2',
    form_search: 'Search',

    widget_title: 'PowerZap Chat',
    widget_user: 'PowerZap Chat',
    widget_msg: 'Hello, I accessed website X and I have some questions.',
    widget_btn: 'Start Chat',

    code_title: 'Easily activate the WhatsApp Button and start using',
    code_text_1: 'Activate your WhatsApp Button with the code below',
    code_text_2: 'Copy and paste this code before the </body> tag',
    code_alert: 'Code copied to clipboard.',
    code_btn: 'COPY CODE',
    code_text_3: 'Now just activate the code on your website and start reaping the rewards! Still have doubts? Check out our tutorial below for additional assistance.',
    code_step: 'RETURN TO STEP 1',
    code_link_1: 'Understand how to activate:',
    code_link_2: 'Check out our tutorials',
    code_link_3: 'Tutorial for WordPress Site',
    code_link_4: 'Tutorial for Bitrix24 Site',
    code_link_5: 'Tutorial for other platforms',
    code_link_6: 'What to do before connecting a number in Bitrix24 CRM',
    code_link_7: 'How to prepare the number before connecting it to Bitrix24 CRM',
}