export const br = {
    hearder_title: 'PowerZap WhatsApp API for Bitrix24',
    header_btn: 'CRIE O SEU AGORA',
    header_text_1: 'Capture instantaneamente o contato dos visitantes do seu site!',
    header_text_2: 'Com o Botão de WhatsApp do PowerZap, você abre as portas para engajar visitantes interessados diretamente em suas ofertas. E a melhor parte? A instalação é simplificada, levando apenas 2 minutos!',

    info_title: 'Como funciona nosso gerador:',
    info_subtitle_1: 'Gerando o código do Botão de WhatsApp',
    info_text_1: 'Após preencher os campos acima, um código vai ser gerado automaticamente.',
    info_item_1: '1. Copie e cole o código dentro da tag {"</body>"} do seu site.',
    info_item_2: '2. Assim que instalado, você começará a receber mensagens no número de WhatsApp que você informou acima.',
    info_item_3: '3. Com o número já conectado ao Bitrix24 com o PowerZap, cada novo lead que demonstrar interesse poderá ser automaticamente um novo Lead no seu Bitrix24 CRM e distribuído para a fila de atendentes.',
    info_subtitle_2: 'Aplicando o código no seu site',
    info_text_2: 'Se você não entende de HTML, mas não quer depender de um desenvolvedor',
    info_text_3: 'Não se preocupe. Nosso tutorial intuitivo estará disponível para guiá-lo(a) durante todo o processo.',
    info_text_4: 'Se você não entende de HTML e não quer arriscar',
    info_text_5: 'Basta encaminhar o código para um desenvolvedor de confiança.',

    form_title: 'Vamos criar e personalizar seu Botão de WhatsApp',
    form_user: 'Qual nome de usuário mostrar no Botão de WhatsApp?',
    form_invalid_user: 'Por favor, insira um nome de usuário válido.',
    form_msg: 'Qual mensagem mostrar no topo para seus clientes?',
    form_placeholder_msg: 'Olá, acessei o site X e tenho algumas dúvidas.',
    form_invalid_msg: 'Por favor, insira uma mensagem válida.',
    form_btn: 'Qual texto mostrar no Botão de WhatsApp?',
    form_placeholder_btn: 'Iniciar Chat',
    form_number: 'Qual número de WhatsApp você quer usar?',
    form_submit: 'CRIAR BOTÃO DE WHATSAPP',
    form_step: 'PASSO 1 DE 2',
    form_search: 'Pesquisar',

    widget_title: 'PowerZap Chat',
    widget_user: 'PowerZap Chat',
    widget_msg: 'Olá, acessei o site X e tenho algumas dúvidas.',
    widget_btn: 'Iniciar Chat',

    code_title: 'Ative facilmente o Botão de WhatsApp e comece a usar',
    code_text_1: 'Ative seu Botão de WhatsApp com o código abaixo',
    code_text_2: 'Copie e cole este código antes da tag </body>',
    code_alert: 'Código copiado para a área de transferência.',
    code_btn: 'COPIE O CÓDIGO',
    code_text_3: 'Agora é só ativar o código em seu site e começar a colher os frutos! Ainda tem dúvidas? Confira nosso tutorial ao lado para obter assistência adicional.',
    code_step: 'VOLTAR AO PASSO 1',
    code_link_1: 'Entenda como ativar:',
    code_link_2: 'Confira nossos tutoriais',
    code_link_3: 'Tutorial para Site WordPress',
    code_link_4: 'Tutorial para Site Bitrix24',
    code_link_5: 'Tutorial para outras plataformas',
    code_link_6: 'O que fazer antes de conectar um número no Bitrix24 CRM',
    code_link_7: 'Como preparar o número antes de conectá-lo no Bitrix24 CRM',
}