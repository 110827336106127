import React, { useState } from 'react';
import { Popover, Collapse } from 'antd';
import { FormattedMessage } from 'react-intl';

const { Panel } = Collapse;

function WidgetCode({
    message,
    user,
    buttonName,
    whatsappNumber,
    setGeneratedWidget,
    scrollToForm,
}) {
    const [open, setOpen] = useState(false);
    const code = `<script>window.rwbp={phone:'${whatsappNumber}',message:'${message}',user:'${user}',button:'${buttonName}'}</script><script src='https://br24-widget.s3.amazonaws.com/bundle.js'></script>`;

    const copyToClipboard = () => {
        navigator.clipboard.writeText(code)
            .then(() => {
                console.log('Texto copiado com sucesso para a área de transferência.');
            })
            .catch((error) => {
                console.error('Erro ao copiar texto:', error);
            });
    };

    const hide = () => {
        setOpen(false);
    };

    const handleOpenChange = (newOpen) => {
        setOpen(newOpen);
    };

    const back = () => {
        setGeneratedWidget(true);
        scrollToForm();
    }

    return (
        <div className="container-fluid p-4">
            <h3 className="d-flex align-items-center justify-content-center"><FormattedMessage id="code_title" /></h3>
            <div className="row pt-4 p-3">
                <div className="col-md-6 col-12 p-4 bg-custom-code shadow">
                    <h5><FormattedMessage id="code_text_1" /></h5>
                    <span><FormattedMessage id="code_text_2" /></span><br />

                    <textarea readOnly className="form-control mt-4 mb-3" name="code" cols="50" rows="4">
                        {code}
                    </textarea>

                    <Popover placement="right" content={<a onClick={hide}><FormattedMessage id="code_alert" /></a>} trigger="click" open={open} onOpenChange={handleOpenChange}>
                        <button type="button" className="btn btn-custom-code mb-3" onClick={copyToClipboard} data-toggle="popover" data-content="">
                            <FormattedMessage id="code_btn" />
                            <img className="img-copy" src={require(`../assets/img/copy.png`)} />
                        </button>
                    </Popover><br />

                    <span><FormattedMessage id="code_text_3" /></span>

                    <div className="pt-2">
                        <div class="progress">
                            <div class="progress-bar custom-progress" role="progressbar" style={{ width: '100%' }} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                        <span className="d-flex align-items-center justify-content-center p-1">
                            <button type="button" className="btn btn-arrow" onClick={back}><img className="img-arrow" src={require(`../assets/img/arrow-left.png`)} /></button>
                            <FormattedMessage id="code_step" />
                        </span>
                    </div>
                </div>

                <div className="col-md-6 col-12 p-3 p-2">
                    <h3><FormattedMessage id="code_link_1" /></h3>

                    <div className="pt-2">
                        <Collapse accordion="false">
                            <Panel header={<FormattedMessage id="code_link_2" />} key="1">
                                <ul>
                                    <li><a href="https://br24-widget.s3.amazonaws.com/Tutorial+Bot%C3%A3o+de+Whatsapp+-+Site+Wordpress.mp4" target="_blank"><FormattedMessage id="code_link_3" /></a></li>
                                    <li><a href="https://br24-widget.s3.amazonaws.com/Tutorial+Bot%C3%A3o+de+Whatsapp+-+Site+Bitrix24.mp4" target="_blank"><FormattedMessage id="code_link_4" /></a></li>
                                    <li><a href="https://br24-widget.s3.amazonaws.com/Tutorial+Bot%C3%A3o+de+Whatsapp+-+Site+em+outras+plataformas.mp4" target="_blank"><FormattedMessage id="code_link_5" /></a></li>
                                    <li><a href="https://ajuda.br24.io/#b31204" target="_blank"><FormattedMessage id="code_link_6" /></a></li>
                                    <li><a href="https://ajuda.br24.io/#b31208" target="_blank"><FormattedMessage id="code_link_7" /></a></li>
                                </ul>
                            </Panel>
                        </Collapse>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WidgetCode;
