import { Input } from 'antd';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import pt from 'react-phone-input-2/lang/pt.json';

function WidgetForm({
    message,
    setMessage,
    whatsappNumber,
    setWhatsappNumber,
    user,
    setUser,
    buttonName,
    setButtonName,
    locale,
    setGeneratedWidget,
}) {

    const intl = useIntl();

    const handleMessageChange = (value) => {
        setMessage(value.target.value);
    };

    const handleUserChange = (value) => {
        setUser(value.target.value);
    };

    const handleButtonNameChange = (value) => {
        setButtonName(value.target.value);
    };

    const handleNumberChange = (value) => {
        setWhatsappNumber(value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const forms = document.querySelectorAll('.needs-validation');

        forms.forEach(function (form) {
            if (!form.checkValidity()) {
                event.preventDefault();
                event.stopPropagation();
            } else {
                const buttonName = document.getElementById('forNameButton').value;
                if (!buttonName) {
                    if (locale == 'br') setButtonName('Iniciar Chat');
                    else if (locale == 'en') setButtonName('Start Chat');
                    else setButtonName('Comenzar chat');
                }
                setGeneratedWidget(false);
            }

            form.classList.add('was-validated')
        });
    };

    return (
        <div>
            <form onSubmit={handleSubmit} class="needs-validation" noValidate>
                <div className="d-flex align-items-center justify-content-center text-center p-4">
                    <h4><FormattedMessage id="form_title" /></h4>
                </div>

                <div className="pb-3">
                    <FormattedMessage id="form_user" />
                    <Input
                        type="text"
                        className="form-control"
                        id="forUser"
                        placeholder="PowerZap Chat"
                        value={user}
                        onChange={handleUserChange}
                        required
                    />
                    <div class="invalid-feedback"><FormattedMessage id="form_invalid_user" /></div>
                </div>

                <div className="pb-3">
                    <FormattedMessage id="form_msg" />
                    <Input
                        type="text"
                        className="form-control"
                        id="forMsg"
                        placeholder={intl.formatMessage({ id: "form_placeholder_msg" })}
                        value={message}
                        onChange={handleMessageChange}
                        required
                    />
                    <div class="invalid-feedback"><FormattedMessage id="form_invalid_msg" /></div>
                </div>

                <div className="pb-3">
                    <FormattedMessage id="form_btn" />
                    <Input
                        type="text"
                        className="form-control"
                        id="forNameButton"
                        placeholder={intl.formatMessage({ id: "form_placeholder_btn" })}
                        value={buttonName}
                        onChange={handleButtonNameChange}
                    />
                </div>

                <div className="pb-3">
                    <FormattedMessage id="form_number" />
                    <PhoneInput
                        autoFormat
                        value={whatsappNumber}
                        enableSearch={true}
                        disableSearchIcon={true}
                        preferredCountries={['br', 'us', 'es']}
                        inputClass="inp-cls"
                        containerClass="cust"
                        dropdownClass="dropd"
                        countryCodeEditable={false}
                        onChange={handleNumberChange}
                        searchPlaceholder={intl.formatMessage({ id: "form_search" })}
                        inputProps={{
                            required: true,
                            style: {
                                width: '100%',
                                height: '38px',
                            },
                        }}
                        localization={pt}
                    />
                </div>

                <div className="pt-3 d-flex align-items-center justify-content-center text-center">
                    <button type="submit" className="btn btn-primary">
                        <FormattedMessage id="form_submit" />
                    </button>
                </div>
            </form>

            <div className="col-12 p-3">
                <div class="progress">
                    <div class="progress-bar custom-progress" role="progressbar" style={{ width: '50%' }} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <span className="d-flex align-items-center justify-content-center p-1">
                    <FormattedMessage id="form_step" />
                    <button type="button" className="btn btn-arrow" onClick={handleSubmit}><img className="img-arrow" src={require(`../assets/img/arrow-right.png`)} /></button>
                </span>
            </div>
        </div>
    );
}

export default WidgetForm;
